<template>
  <div class="onecell">
    <base-input>
      <b-input-group>
        <b-input type="text" class="form-control" v-model="input_val" />
        <base-button type="primary" class="mybotton" @click="$emit('inputsubmit', input_val, record)">{{ $t("Change") }}</base-button>
      </b-input-group>
    </base-input>
  </div>
</template>
<script>
export default {
  name: "InputSubmit",
  props: {
    myvalue: {
      type: String,
      required: true
    },
    record: {
      type: Object,
      required: true
    },
  },
  data: function () {
    return {
      input_val: ''
    }
  },
  mounted() {
    this.input_val = this.myvalue;
  },
};
</script>
<style>
.mybotton {
  margin-left: 1rem;
}
.onecell {
  margin-bottom: -1rem;
  margin-top: 1rem;
}
</style>

